<template>
    <div>
        <CustomerDetails :name="name" :product_name="product_name" :full_amount="full_amount" :residence_literal="residence_literal" :destination_literal="destination_literal" :state="state" :email="email" :customers="customers" :end_date.sync="end_date" :start_date.sync ="start_date " :issueDate="issueDate" :customer_dts="customer_dts" :telephone="telephone" :address="address"/>
        <!-- customers table insurance evidence -->
        <b-row class="mb-3">
            <b-col cols="12">
                <b-table striped responsive
                :items="items"
                :fields="fields"
                :busy="isBusy">
                <template #cell(position)="data">
                    {{ data.index + 1 }}
                </template>
                <template #table-busy>
                    <div class="d-flex align-items-center justify-content-around my-2">
                        <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                    </div>
                </template>
                <template #cell(ins_ev)="data">
                    <b-button size="sm" variant="outline-secondary" target="_blank" :href="policyLink+'&tr='+data.index">Download</b-button>
                </template>
                <!-- Edit -->
                <template #cell(actions)="data">
                    <b-button 
                        v-if="ls_role=='admin'" 
                        size="sm" 
                        variant="outline-warning" 
                        @click="openEditModal(data.item)">
                        Edit
                    </b-button>
                </template>
                </b-table>

                <!-- Edit Modal -->
                <b-modal v-model="showEditModal" title="Customer Details" :hide-footer="true">
                    <b-form @submit.prevent="saveChanges">
                        <!-- Gender -->
                        <b-form-group label="Gender">
                            <b-form-select v-model="editedCustomer.gender" :options="genderOptions" required></b-form-select>
                        </b-form-group>
                        <!-- Name -->
                        <b-form-group label="Customer Name">
                            <b-form-input v-model="editedCustomer.customer_name" minlength="6" required></b-form-input>
                        </b-form-group>
                        <!-- Birthdate -->
                        <b-form-group label="Birthdate">
                            <b-form-input v-model="formattedDate" type="date" required></b-form-input>
                        </b-form-group>
                        <!-- Age (Read-only) -->
                        <b-form-group label="Age">
                            <b-form-input v-model="editedCustomer.age" readonly required></b-form-input>
                        </b-form-group>
                         <div class="d-flex justify-content-between">
                            <b-button type="submit" variant="primary">Save Changes</b-button>
                            <b-button type="button" variant="secondary" @click="showEditModal=false">Cancel</b-button>
                         </div>
                    </b-form>
                </b-modal>
            </b-col>

            <b-col cols="12">
                <div v-if="this.policyLink">
                    <b-button size="sm" variant="success" target="_blank" :href="policyLink">Insurance Certificate</b-button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
  <script>
  import { cookie, token_id, ls_role, products, customersDtsMixin } from '@/products';
  import CustomerDetails from './CustomerDetails';
  import { BRow, BCol, BJumbotron, BTable, BButton, BCard, BCardText, BCardTitle, BCardBody, BSpinner, BForm, BFormGroup, BFormInput, BFormSelect } from 'bootstrap-vue'
  
  export default {
    mixins: [customersDtsMixin],
    components: {
      CustomerDetails,
      BRow,
      BCol,
      BJumbotron,
      BTable,
      BButton,
      BCard,
      BCardText,
      BCardTitle,
      BCardBody,
      BSpinner,
      BForm,
      BFormGroup,
      BFormInput,
      BFormSelect
    },
    data() {
        return {
            genderOptions: [
                { value: 'Mr.', text: 'Mr.' },
                { value: 'Mrs.', text: 'Mrs.' }
            ],
            customer_dts: {},
            editedCustomer:{},
            showEditModal: false,
            isBusy: true,
            name: [],
            start_date: '',
            end_date: '',
            state: '',
            issueDate: '',
            email: '',
            product_name: '',
            full_amount: '',
            residence_literal: '',
            destination_literal: '',
            telephone: '',
            address: '',
            customers:[],
            items:[],
            fields: [
                { key: 'position', label: 'No.', sortable: false },
                { key: 'gender', label: 'Gender', sortable: true },
                { key: 'customer_name', label: 'Customer Name', sortable: true },
                { key: 'date_of_birth', label: 'Date of Birth', sortable: true },
                { key: 'age', label: 'Age', sortable: true },
                { key: 'ins_ev', label: 'Insurance Certificate', sortable: false },
                { key: 'actions', label: '', sortable: false},
            ],
            debitnoteLink:'',
            tr_token:'',
            customer_dts:'',
            on_behalf_of:'',
            t_tokenized:'',
            products, 
            policyLink:'',
            tr_token:'',
            on_behalf_of:'',
            cookie,
            token_id,
            ls_role
        }
    },
    computed: {
        formattedDate: {
            get() {
                if (!this.editedCustomer.date_of_birth) return "";
                const parts = this.editedCustomer.date_of_birth.split("/");
                if (parts.length === 3) {
                    return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert DD/MM/YYYY to YYYY-MM-DD for input
                }
                return "";
            },
            set(newValue) {
                if (!newValue) return;
                let [year, month, day] = newValue.split("-"); // Split YYYY-MM-DD
                this.editedCustomer.date_of_birth = `${day}/${month}/${year}`; // Convert back to DD/MM/YYYY

                // Calculate age
                const today = new Date();
                const birthDate = new Date(year, month - 1, day); // Month is zero-based
                let age = today.getFullYear() - birthDate.getFullYear();
                const monthDiff = today.getMonth() - birthDate.getMonth();
                
                // If birthday hasn't occurred this year, subtract 1
                if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }

                this.editedCustomer.age = age;
            }
        }
    },
    methods: {
        openEditModal(data) {
            this.showEditModal = true;
            this.editedCustomer = {...data};
        },
        saveChanges() {
            const url= {
                prod: 'https://api-quote.safetravel.today/v2/app/update_traveller'
            };
            const data = {
                token: this.token_id,
                jwt: this.cookie,
                id: Number(this.customers[0].id),
                customer_name: this.editedCustomer.customer_name,
                date_of_birth: this.editedCustomer.date_of_birth,
                gender: this.editedCustomer.gender,
                age: Number(this.editedCustomer.age),
            }
            // Update
            fetch(url.prod, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    const index = this.items.findIndex(item => item.id === this.editedCustomer.id);
                    if (index !== -1) {
                        this.$set(this.items, index, { ...this.editedCustomer }); // Ensures reactivity
                    }
                    this.items = [...this.items]; // Force Vue to detect the change
                } else {
                    alert("Update failed: " + result.message);
                }
            })
            .catch(error => console.error('Error:', error))
            .finally(() => {this.showEditModal = false;});
        }
    },
    async created() {
        await this.urlParams();
        await this.getTripDts()
        await this.getCustomers();
        await this.getMember(this.on_behalf_of);
        await this.checkCertificate(this.tr_tokenized);
    },
  }
  </script>
  <style>
    #jumDts {
        padding: 2rem;
    }
  </style>